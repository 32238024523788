import axios from 'axios';

class UserService {

    getUserState() {
        return JSON.parse(window.localStorage.getItem('user'));
    }
    setUserState(user) {
        return window.localStorage.setItem('user', JSON.stringify(user));
    }
    getUsers() {
        return axios.get(`${process.env.VUE_APP_APIURL}/users`);
    }
    getUser(id) {
        return axios.get(`${process.env.VUE_APP_APIURL}/users/${id}`);
    }
    updateUser(id, data) {
        return axios.put(`${process.env.VUE_APP_APIURL}/users/${id}`, data);
    }
    deleteUser(id) {
        return axios.delete(`${process.env.VUE_APP_APIURL}/users/${id}`);
    }

    async refreshUser() {
        await this.getUser(this.getUserState().id).then(res => {
            this.setUserState(res.data);
        }); 
    }

    // User Role
    createUserRole(data) {
        return axios.post(`${process.env.VUE_APP_APIURL}/user_roles/`, data);
    }
    getUserRoles(userId, organisationId = null) {
        return axios.get(`${process.env.VUE_APP_APIURL}/user_roles/${userId}/${organisationId ? organisationId : ''}`);
    }
    deleteUserRole(userId, roleId, organisationId = null) {
        return axios.delete(`${process.env.VUE_APP_APIURL}/user_roles/${userId}/${roleId}/${organisationId ? organisationId : ''}`);
    }

    // User Organisation
    createUserOrganisation(data) {
        return axios.post(`${process.env.VUE_APP_APIURL}/user_organisations`, data);
    }
    
    deleteUserOrganisation(orgId, userId) {
        return axios.delete(`${process.env.VUE_APP_APIURL}/user_organisations/${userId}/${orgId}`);
    }

    // Course Enrolled
    canAccessLesson(lesson) {
        if (lesson.without_enroll == true) {
            return true;
        } else if (this.getUserState()) {
            var enrolled = this.getUserState().courses.filter(e => e.id === lesson.courseId).length > 0;
            return enrolled;
        } else {
            return false;
        }
    }

    // Eligible to take quiz
    canAccessQuiz(userAnswer) {
        var pass = true;

        // Already attempted the quiz more than 3 times
        if (userAnswer.length > 2) {
            return false;
        }
        
        // Check if user has passed the quiz (totalScore/ quiz score mapped from questions)
        userAnswer.forEach(element => {
            var quizScore = 0;
            element.quiz.question.forEach(question => {
                question.marks.forEach(mark => {
                    quizScore += +mark;
                });
            });

            // At least one assignment has already passed so no reason to take the test anymore
            if (element.totalScore / quizScore > 0.5) {
                pass = false;
                return;
            }

            // If there's an unmarked quiz, cannot proceed
            if (!element.status || element.status == 'Submitted') {
                pass = false;
                return;
            }
        });

        if (pass) {
            return true;
        } else {
            return false;
        }
    }
}


export default new UserService();
